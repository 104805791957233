$animation-duration: 0.15s;

.form {
  &-layout {
    margin: 0 auto;
    padding: 108px 20px 40px;
    max-width: 1219px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media #{$site-tablet-breakpoint} {
      flex-direction: column;
      max-width: 760px;
      padding: 122px 40px 40px;
      margin: 0 auto;
    }
    @media #{$site-mobile-breakpoint} {
      padding: 82px 20px 40px;
    }
  }

  &-content {
    @include flex-width(58.4%);
    padding: 12px 0 0 18px;
    @media #{$site-tablet-breakpoint} {
      padding-left: 0;
      @include flex-width(100%);
      flex-basis: auto;
    }

    hr {
      border: none;
      height: 1px;
      background: #d9d9d9;
      margin: 22px 0 10px;
    }

    &.centered {
      margin: 0 auto;
      @include flex-width(100%);
      flex-basis: auto;
      max-width: 688px;
    }

    h1 {
      font-size: 32px;
      margin-bottom: 14px;
      @media #{$site-mobile-breakpoint} {
        line-height: 1.2;
        font-size: 24px;
        margin-bottom: 18px;
      }
    }

    .sampled-paragraph {
      font-size: 20px;
      margin-bottom: 38px;
      font-weight: 700;
      .small {
        display: block;
        font-size: 16px;
        font-weight: 400;
        padding-top: 7px;
      }
      @media #{$site-mobile-breakpoint} {
        line-height: 1.2;
        margin-bottom: 35px;
      }

      &.mb26 {
        margin-bottom: 26px;
        @media #{$site-mobile-breakpoint} {
          margin-bottom: 20px;
        }
      }
    }
  }

  &-aside {
    @include flex-width(33.99%);
    background-color: #f4f6f7;
    margin-top: 7px;
    @media #{$site-tablet-breakpoint} {
      @include flex-width(100%);
      flex-basis: auto;
    }
    > div {
      padding: 49px 35px 39px;
      @media #{$site-mobile-breakpoint} {
        padding: 28px 14px 20px;
      }
    }
  }

  &-success {
    flex-grow: 1;
    background-color: #eff2f3;

    .form-aside {
      background-color: #fff;
    }

    .form-content {
      padding-left: 0;
      @media #{$site-tablet-breakpoint} {
        padding-left: 17px;
      }
      @media #{$site-mobile-breakpoint} {
        padding-left: 0;
      }

      h1 {
        font-size: 42px;
        margin-bottom: 17px;
        line-height: 1.1;
        @media #{$site-tablet-breakpoint} {
          font-size: 32px;
          line-height: 1.38;
          margin-bottom: 20px;
        }
        @media #{$site-mobile-breakpoint} {
          font-size: 24px;
        }
      }

      .sampled-paragraph {
        font-size: 20px;
        margin-bottom: 65px;
        @media #{$site-tablet-breakpoint} {
          line-height: 1.2;
          margin-bottom: 40px;
        }
        @media #{$site-mobile-breakpoint} {
          margin-bottom: 35px;
        }
      }
    }

    .form-aside {
      margin: 8px 52px 0 0;
      @include flex-width(28.59%);
    }

    &__notes {
      strong {
        display: block;
        font-size: 24px;
        margin-bottom: 39px;
        @media #{$site-tablet-breakpoint} {
          margin-bottom: 20px;
        }
      }

      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-right: 27px;
        @media #{$site-tablet-breakpoint} {
          margin-right: 15px;
        }
      }

      li {
        position: relative;
        line-height: 1.5;
        padding: 73px 0 32px 0;
        flex-basis: 30%;
        min-width: 110px;
        @media #{$site-tablet-breakpoint} {
          font-size: 14px;
        }
        @media #{$site-mobile-breakpoint} {
          padding: 63px 0 5px 0;
        }

        .icon {
          position: absolute;
          top: 5px;
          left: -2px;
          width: 47px;
          height: 47px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }

    &__share {
      h3 {
        font-size: 24px;
        line-height: 1.5;
        margin: 0 20px 22px 0;
        @media #{$site-tablet-breakpoint} {
          margin: 0 0 8px 0;
        }
      }

      p {
        line-height: 1.5;
        margin: 0 20px 29px 0;
        @media #{$site-tablet-breakpoint} {
          margin: 0 0 20px 0;
        }
      }

      .gs-green-btn + .gs-green-btn {
        margin-top: 16px;
      }

      .twitter:before,
      .facebook:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 27px;
        height: 22px;
        background: url(../img/svg/success-twitter.svg) 50% 50% no-repeat;
        background-size: 100% 100%;
        margin-right: 15px;
        position: relative;
        top: -1px;
      }

      .facebook:before {
        width: 17px;
        height: 27px;
        background: url(../img/svg/success-facebook.svg) 50% 50% no-repeat;
      }
    }

    .form-layout {
      flex-direction: row;
      padding-top: 121px;
      max-width: 1219px;
      @media #{$site-tablet-breakpoint} {
        max-width: none;
        padding: 105px 46px 40px;
      }
      @media #{$site-mobile-breakpoint} {
        flex-direction: column;
        padding: 105px 20px 0;
      }

      .form-aside {
        @media #{$site-tablet-breakpoint} {
          margin-right: 0;
          @include flex-width(35.3%);
        }
        @media #{$site-mobile-breakpoint} {
          @include flex-width(100%);
          flex-basis: auto;
          margin-bottom: 50px;
        }
        & > div {
          @media #{$site-tablet-breakpoint} {
            padding: 22px 20px;
          }
          @media #{$site-mobile-breakpoint} {
            padding: 20px;
          }
        }
      }

      .form-content {
        @media #{$site-tablet-breakpoint} {
          @include flex-width(60%);
        }
        @media #{$site-mobile-breakpoint} {
          @include flex-width(100%);
          flex-basis: auto;
          margin-bottom: 9px;
        }
      }
    }
  }
}

.gs-form {
  &-fieldset {
    position: relative;
    margin-bottom: 16px;
  }

  &-label {
    display: block;
    margin-bottom: 7px;
  }

  &-error {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 10px;
    line-height: 1.5;
    padding-top: 1px;
    color: #a62803;
    background: #fff;
    font-weight: 700;
  }

  &-double-row {
    display: flex;
    justify-content: space-between;
    @media #{$site-mobile-breakpoint} {
      flex-direction: column;
    }

    .gs-form-fieldset {
      @include flex-width(48.52%);
      @media #{$site-mobile-breakpoint} {
        @include flex-width(100%);
        flex-basis: auto;
      }
    }

    &.address-row {
      .gs-form-fieldset {
        @include flex-width(80.57%);
        @media #{$site-mobile-breakpoint} {
          @include flex-width(100%);
          flex-basis: auto;
        }

        & + .gs-form-fieldset {
          @include flex-width(16.52%);
          @media #{$site-mobile-breakpoint} {
            @include flex-width(100%);
            flex-basis: auto;
          }
        }
      }
    }
  }

  &-checkbox-wrap {
    padding: 11px 0 14px;
    font-size: 14px;
    color: #6f7276;
    position: relative;

    .gs-form-error {
      top: auto;
      bottom: 0;
    }

    @media #{$site-tablet-breakpoint} {
      padding: 0 0 14px 0;
      margin-bottom: 8px;
    }

    .gs-form-error ~ label:before {
      border-color: #a62803;
    }
  }
}

input.gs-form-input {
  width: 100%;
  height: 32px;
  border-radius: 4px;
  border: solid 1px #b3b3b3;
  font-size: 16px;
  padding: 0 12px;
  color: #000;

  &::-webkit-input-placeholder {
    color: #b3b3b3;
  }

  &:-ms-input-placeholder {
    color: #b3b3b3;
  }

  &::-ms-input-placeholder {
    color: #b3b3b3;
  }

  &::placeholder {
    color: #b3b3b3;
  }

  &:focus {
    border-color: #0d8e7b;
    outline: none;
  }

  .gs-form-error + & {
    border-color: #a62803;
  }
}

.gs-form-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0 0 0 30px;
    display: block;
    line-height: 1.3;
    min-height: 21px;
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 14px;
    display: block;
    vertical-align: middle;
    position: absolute;
    top: -1px;
    left: 0;
    width: 17px;
    height: 17px;
    border-radius: 4.8px;
    border: solid 1.6px #b3b3b3;
    background-color: #ffffff;
    transition: all $animation-duration ease;
  }

  // Box hover
  &:hover + label:before {
    border-color: #03a678;
  }

  // Box checked
  &:checked + label:before {
    background: #03a678;
    border-color: #03a678;
  }

  // Disabled state label.
  &:disabled + label {
    opacity: 0.5;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 8px;
    background: white;
    width: 3px;
    height: 3px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}

.gs-form-btn {
  height: 48px;
  border-radius: 4px;
  background-color: #03a678;
  font-size: 16px;
  border: none;
  color: rgba(255, 255, 255, 0.87);
  padding: 0 20px;
  transition: background-color $animation-duration ease;

  &.block {
    display: block;
    width: 100%;
  }

  &:hover {
    background-color: #085547;
  }

  &:disabled {
    cursor: default;
    background-color: #b4c1c5;

    &:hover {
      background-color: #b4c1c5;
    }
  }
}

.custom-radio {
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 32px;
    line-height: 1.48;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    border: solid 1.6px #b8c5c8;
    border-radius: 100%;
    background: #fff;
    transition: all 0.2s ease;
    box-sizing: border-box;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 13px;
    height: 13px;
    background: #03a678;
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
  }
}

.lined-radios {
  padding-bottom: 10px;

  .custom-radio {
    display: inline-block;

    & + .custom-radio {
      margin-left: 33px;
    }
  }
}

.custom-select {
  .select__control {
    position: relative;
    width: 100%;
    height: 32px;
    min-height: 32px;
    border-radius: 4px;
    border: solid 1px #dddbda;
    background-color: #ffffff;
    box-shadow: none !important;
    padding: 0 12px;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #000;

    &--is-disabled {
      opacity: 0.5;
    }

    &--is-focused {
      border-color: #0d8e7b !important;
      outline: none;
    }

    &:hover {
      border-color: #dddbda;
      outline: none;
    }

    * {
      padding: 0;
      margin: 0;
    }
  }

  .select__value-container {
    padding: 0;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__dropdown-indicator {
    position: relative;

    svg {
      display: none;
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -5px;
      margin-left: -8px;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 8px 0 8px;
      border-color: #000 transparent transparent transparent;
      content: "";
    }
  }

  .select__menu {
    border-radius: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
  }

  .select__option {
    padding: 6px 9px;
    color: #737373;

    &:hover {
      background-color: rgba(rgba(242, 242, 242, 1), 0.4);
    }

    &--is-selected,
    &--is-active {
      background-color: #f2f2f2 !important;
    }

    &--is-disabled {
      opacity: 0.5 !important;
    }
  }
}

.main-form {
  fieldset {
    position: relative;
  }

  label {
    display: block;
    margin-bottom: 4px;
    //padding-right: 76px;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #333333;
  }

  input,
  textarea {
    width: 100%;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #dddbda;
    background-color: #ffffff;
    padding: 0 12px;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #000;

    &::-webkit-input-placeholder {
      color: #b3b3b3;
    }

    &::-moz-placeholder {
      color: #b3b3b3;
    }

    &:-ms-input-placeholder {
      color: #b3b3b3;
    }

    &:-moz-placeholder {
      color: #b3b3b3;
    }

    &:focus {
      border-color: #0d8e7b;
      outline: none;

      &::-webkit-input-placeholder {
        color: transparent;
      }

      &::-moz-placeholder {
        color: transparent;
      }

      &:-ms-input-placeholder {
        color: transparent;
      }

      &:-moz-placeholder {
        color: transparent;
      }
    }
  }

  textarea {
    height: 120px;
    padding: 5px 12px;
  }

  select {
    min-width: 138px;
    height: 32px;
    padding: 0 12px;
    border-radius: 2px;
    border: solid 1px #dddbda;
    background-color: #ffffff;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #000;

    &:focus {
      border-color: #0d8e7b;
      outline: none;
    }
  }

  &__group {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: solid 1px #b8c5c8;

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  &__double {
    display: flex;
    justify-content: space-between;

    @media #{$site-mobile-breakpoint} {
      display: block;
    }

    fieldset {
      flex: 0 0 48.4%;

      @media #{$site-mobile-breakpoint} {
        flex: 0 0 100%;
      }
    }

    &_first-long {
      fieldset {
        &:nth-child(1) {
          flex: 0 0 73.4%;
        }

        &:nth-child(2) {
          flex: 0 0 23.4%;
        }
      }

      fieldset {
        flex: 0 0 48.4%;

        @media #{$site-mobile-breakpoint} {
          flex: 0 0 100%;
        }
      }
    }
  }

  &__btn {
    margin-top: 9px;
  }
}

.plan-type-chooser {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  padding: 22px 0 26px;
  margin: -7px 0 22px;

  .sampled-paragraph {
    margin-bottom: 21px;
  }
}
