@import "./variables";
@import "./colors";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";

$heading-font-family: Poppins;
$heading-font-weight: 900;
$heading-2-size: 32px;
$heading-2-line-height: 54px;
$heading-3-size: 28px;
$heading-3-line-height: 42px;

%heading-base {
  color: $text-color;
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  margin-bottom: 24px;
}

// 42px
.heading-1 /*, h1 */ {
  @extend %heading-base;

  margin-top: 40px;
  font-size: $heading-3-size;
  line-height: $heading-3-line-height;

  @include media-breakpoint-up("md") {
    font-size: $heading-2-size;
    line-height: $heading-2-line-height;
  }

  @include media-breakpoint-up("lg") {
    font-size: 42px;
    line-height: 63px;
  }

  &:first-child {
    margin-top: 0;
  }
}

// 28px
.heading-2 /*, h2 */ {
  @extend %heading-base;

  font-size: $heading-3-size;
  line-height: $heading-3-line-height;

  @include media-breakpoint-up("md") {
    font-size: $heading-2-size;
    line-height: $heading-2-line-height;
  }
}

// 24px
.heading-3 /*, h3 */ {
  @extend %heading-base;

  font-size: $heading-3-size;
  line-height: $heading-3-line-height;
}

// 16px
.heading-4 /*, h4 */ {
  @extend %heading-base;

  font-size: 24px;
  line-height: 36px;
}

$text-font-family: Lato;
$text-regular-weight: normal;
$text-bold-weight: 700;
$text-default-line-height: 24px;
$text-default-size: 16px;

%text-base {
  color: $text-color;
  font-family: $text-font-family;
  font-weight: $text-regular-weight;
  margin-bottom: $text-default-line-height;
}

// 20px bold
.text-6 {
  @extend %text-base;
  font-size: 20px;
  font-weight: $text-bold-weight;
  line-height: 30px;
}

// 20px
.text-5 {
  @extend %text-base;
  font-size: 20px;
  line-height: 30px;
}

// 16px bold
.text-4 {
  @extend %text-base;
  font-size: 16px;
  font-weight: $text-bold-weight;
  line-height: 24px;
}

// 16px
.text-3 {
  @extend %text-base;
  font-size: 16px;
  line-height: 24px;
}

// 14px
.text-2 {
  @extend %text-base;
  font-size: 14px;
  line-height: 21px;
}

// 10px
.text-1 {
  @extend %text-base;
  font-size: 10px;
  font-weight: $text-bold-weight;
  line-height: 10px;
}

.error {
  @extend %text-base;
  line-height: 24px;
  font-size: 10px;
  font-weight: $text-bold-weight;
  color: $alert-red;
}
