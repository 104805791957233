$animation-duration: 0.15s;

@mixin retina-back($image, $ext, $repeat, $position) {
  background: url($image + "." + $ext) $repeat $position;
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background: url($image + "@2x" + "." + $ext) $repeat $position;
  }
}

@mixin retina-back-external($image, $ext, $repeat, $position, $color, $size) {
  background: url($image + "-1x." + $ext) $repeat $position $color;
  background-size: $size;

  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background: url($image + "-2x." + $ext) $repeat $position $color;
    background-size: $size;
  }
}

@mixin flex-width($width) {
  width: $width;
  flex-basis: $width;
}

$btn-width: 295px;

$site-mobile-width: 650px;
$site-tablet-width: 920px;

$site-mobile-breakpoint: "screen and (max-width:#{$site-mobile-width})";
$site-tablet-breakpoint: "screen and (max-width:#{$site-tablet-width})";

$header-tablet-width: 1260px;
$header-tablet-breakpoint: "screen and (max-width:#{$header-tablet-width})";
$header-mobile-breakpoint: "screen and (max-width:720px)";

$footer-mobile-breakpoint: "screen and (max-width:750px)";

$plans-grid-desktop-m-breakpoint: "screen and (max-width:1200px)";
$plans-grid-mobile-breakpoint: "screen and (max-width:620px)";

$site-imaged-block-tablet: "screen and (max-width:755px)";

@mixin mobile {
  @media #{$site-mobile-breakpoint} {
    @content;
  }
}

@mixin tablet {
  @media #{$site-tablet-breakpoint} {
    @content;
  }
}
