// Color guide reference
// https://entergy.invisionapp.com/share/RKUM7ZZGY8Q

// Brand colors
$primary-green: #03A678;
$green-2: #015940;
$green-3: #4BA684;
$green-4: #002A33;  // very dark green (e.g., footer, etc.)

$primary-blue: #1B3640;
$blue-2: #1d8ff2;

// Grayscale colors
$gray-1: #f2f2f2;
$gray-2: #d9d9d9;
$gray-3: #b3b3b3;
$gray-4: #737373;
$gray-5: #333333;
$primary-white: #ffffff;
$primary-black: #000000;

$transparent: transparent;

// Alert colors
$alert-red: #a62803;
$alert-orange: #f28705;
$alert-yellow: #ffe122;

// Visualization colors
$viz-blue-1: #123f5b;
$viz-purple-1: #955096;
$viz-purple-2: #58508d;
$viz-purple-3: #bc4f8f;
$viz-red-1: #f46362;
$viz-yellow-1: #f7a604;

// background and border colors
$border-back-color: $gray-1;
$footer-back-color: $green-4;
$green-back-color: $primary-green;
$blue-back-color: $primary-blue;
$site-back-color: $primary-white;
$inverse-border-color: $primary-white;
$menu-border-color: $green-2;
$mobile-table-border-color: $gray-2;
$table-border-color: $gray-3;

// text colors
$text-color: $primary-black;
$secondary-text-color: $text-color;
$clickable-text-color: $primary-green;
$disabled-text-color: $gray-3;
$error-text-color: $alert-red;
$heading-text-color: $text-color;
$highlight-text-color: $primary-green;
$inverted-text-color: $primary-white;
$unselected-text-color: $gray-4;

// field colors
$field-border: $gray-3;
$select-chevron-color: $green-3;

// button colors
$btn-back-color: $primary-green;
$btn-text-color: $inverted-text-color;
$btn-hover-back-color: $green-2;
$btn-disabled-back-color: $gray-3;

$bare-btn-text-color: $clickable-text-color;
$bare-btn-hover-text-color: $green-2;
$bare-btn-disabled-text-color: $disabled-text-color;

$dark-btn-back-color: $green-4;
$dark-btn-border-color: $green-4;
$dark-btn-text-color: $inverted-text-color;
$dark-btn-hover-back-color: $primary-white;
$dark-btn-hover-border-color: $primary-white;
$dark-btn-hover-text-color: $green-4;

$inverted-btn-back-color: $transparent;

$outlined-btn-border-color: $primary-green;
$outlined-btn-text-color: $primary-green;
$outlined-btn-hover-back-color: $primary-green;
$outlined-btn-hover-text-color: $inverted-text-color;

$inverted-white-btn-border-color: $primary-white;
$inverted-white-btn-text-color: $inverted-text-color;
$inverted-white-btn-hover-back-color: $primary-white;
$inverted-white-btn-hover-text-color: $transparent;
