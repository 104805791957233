@import "./variables";

@mixin display-flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}

@mixin flex-basis($width, $sync-width: false) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;

  @if $sync-width {
    width: $width;
  }
}

// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  -ms-flex-align: $align;
  align-items: $align;
}

@mixin flexbox($flow, $align) {
  @include display-flex();
  @include align-items($align);
  @include flex-flow($flow);
}

// namespacing for now until it can be removed altogether
.gs-grid {
  [class^="col"] {
    box-sizing: border-box;
  }

  [class^="col"] {
    &.centered {
      text-align: center;
    }
  }

  .row {
    @include flexbox(row wrap, flex-start);
  }

  .nowrap {
    @include flex-flow(row nowrap);
  }

  .reverse {
    @include flex-direction(column-reverse);
  }

  //.reverse-s {
  //  @include mobile {
  //    @include flex-direction(column-reverse);
  //  }
  //}

  .align-items-center {
    @include align-items(center);
  }
}
