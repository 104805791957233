.content {
  font-family: "Lato", sans-serif;
  font-size: 20px;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.87);
  max-width: 822px;
  padding: 58px 20px;

  @media #{$site-tablet-breakpoint} {
    max-width: 620px;
    padding-top: 44px;
  }

  @media #{$site-mobile-breakpoint} {
    padding-top: 28px;
  }

  h2,
  h3,
  h4,
  h5,
  p,
  li {
    margin-bottom: 24px;
  }

  > strong {
    display: block;
    margin-bottom: 18px;
  }

  h2,
  h3,
  h4,
  h5 {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
  }

  h2 {
    font-size: 42px;
    margin-bottom: 42px;

    @media #{$site-mobile-breakpoint} {
      font-size: 38px;
    }
  }

  h3 {
    font-size: 20px;
  }

  strong {
    letter-spacing: -0.4px;
  }

  a {
    color: #0d8e7b;
    text-decoration: none;
  }

  ul,
  ol {
    padding-left: 24px;
    margin-bottom: 26px;
    margin-top: 26px;

    li {
      margin-bottom: 18px;
    }
  }

  ul {
    list-style-type: disc;

    li {
      margin-bottom: -1px;
    }
  }
  ol {
    list-style-type: lower-alpha;
  }
}
