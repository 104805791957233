@import "./variables";
@import "./colors";

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
}

body,
html {
  font-size: 16px;
  color: #212121;
  font-family: "Lato", sans-serif;
  height: 100%;
}

a {
  text-decoration: underline;
  color: #03a678;

  &:hover {
    text-decoration: none;
  }
}

input,
textarea {
  -webkit-appearance: none;
}

h2.content-heading {
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 64px;
  @media #{$site-tablet-breakpoint} {
    font-size: 32px;
    letter-spacing: 0;
    margin-bottom: 29px;
  }
  @media #{$site-mobile-breakpoint} {
    line-height: 1.28;
  }
}

button {
  outline: none;

  &:focus,
  &:active {
    outline: none;
  }
}

.btn,
a.btn {
  width: $btn-width;
  height: 48px;
  line-height: 48px;
  display: inline-block;
  text-align: center;
  border-radius: 6px;
  background-color: #03a678;
  color: rgba(255, 255, 255, 0.87);
  text-decoration: none;
  font-size: 16px;
  transition: all $animation-duration ease;
  border: none;

  &:hover {
    background-color: #037453;
    color: #fff;
  }

  &.centered {
    margin: 0 auto;
    display: block;
  }

  &:disabled,
  &:disabled:hover {
    cursor: default;
    background: #d3dbdd;
  }

  &.outlined {
    background: transparent;
    border: 1px solid #03a678;
    color: #03a678;

    &:hover {
      background-color: #03a678;
      color: #fff;
    }
  }

  &.black {
    background: #002a33;
    border: 1px solid #002a33;
    color: #fff;

    &:hover {
      background-color: #fff;
      color: #002a33;
      border-color: #fff;
    }
  }
}

.centered-content {
  width: 100%;
  max-width: 1440px;
  padding: 0 26px;
  margin: 0 auto;
}

#root,
#App {
  min-height: 100%;
  height: 100%;
}

#root {
  display: flex;
  align-items: stretch;
}

#App {
  flex-basis: 100%;

  & > div {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.gs-green-btn {
  height: 48px;
  line-height: 48px;
  border-radius: 6px;
  background-color: #03a678;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.87);
  padding: 0 20px;
  transition: background-color $animation-duration ease;
  text-align: center;
  text-decoration: none;
  border: none;

  &.block {
    display: block;
  }

  &.blocked {
    display: block;
    width: 100%;
  }

  &:hover {
    background-color: #085547;
  }

  &:disabled,
  &:disabled:hover {
    background-color: #ebeeef;
    color: #fff;
  }
}

.inner-header {
  background-color: #e0e5e8;
  color: rgba(0, 0, 0, 0.87);

  &.dark {
    background-color: #002a33;
    color: #fff;
  }

  &.green {
    background-color: #03a678;
    color: #fff;
  }

  @media #{$site-mobile-breakpoint} {
    padding-top: 70px;
  }

  &__content {
    margin: 0 auto;
    max-width: 1000px;
    padding: 118px 20px 82px;
    min-height: 460px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    @media #{$site-mobile-breakpoint} {
      padding: 27px 20px 37px;
      min-height: auto;
      height: auto !important;
    }

    h1 {
      font-family: "Poppins", sans-serif;
      font-size: 42px;
      font-weight: 900;
      line-height: 1.2;
      margin-bottom: 26px;
      @media #{$site-mobile-breakpoint} {
        font-size: 24px !important;
        margin-bottom: 20px;
      }
    }

    .descr {
      font-size: 20px;
      line-height: 1.2;
    }
  }
}

.imaged-block {
  margin: 0 auto;
  max-width: 1114px;
  display: flex;
  color: rgba(0, 0, 0, 0.87);
  align-items: center;
  @media #{$site-imaged-block-tablet} {
    flex-direction: column;
  }

  .img {
    @include flex-width(500px);
    min-width: 500px;
    height: 500px;
    background-color: #e0e5e8;
    display: flex;
    align-items: center;
    justify-content: center;
    @media #{$site-tablet-breakpoint} {
      @include flex-width(365px);
      min-width: 365px;
      height: 365px;
    }
    @media #{$site-mobile-breakpoint} {
      @include flex-width(280px);
      height: 280px;
      min-width: 280px;
      margin-bottom: 41px;
    }

    img {
      max-width: 100%;
    }
  }

  .text {
    flex-grow: 1;
    max-width: 425px;
    margin: 0 0 25px 80px;

    h2 {
      font-family: "Poppins", sans-serif;
      margin-bottom: 22px;
      font-size: 24px;
      font-weight: 900;
      text-align: left;
      @media #{$site-tablet-breakpoint} {
        line-height: 1.17;
      }
      @media #{$site-mobile-breakpoint} {
        margin-bottom: 15px;
      }
    }

    p {
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 35px;
      @media #{$site-mobile-breakpoint} {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 21px;
      }
    }

    @media #{$site-tablet-breakpoint} {
      margin: 0 0 15px 54px;
      padding-top: 12px;
    }
    @media #{$site-mobile-breakpoint} {
      margin: 0 0 0 19px;
      padding-top: 4px;
    }
  }
}

.pre-footer-banner {
  background-color: #e0e5e8;
  color: rgba(0, 0, 0, 0.87);

  &__content {
    padding: 85px 40px 123px;
    max-width: 1200px;
    margin: 0 auto;
    @media #{$site-mobile-breakpoint} {
      padding: 66px 40px 51px;
    }
  }

  h2 {
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 47px;
    @media #{$site-mobile-breakpoint} {
      margin-bottom: 39px;
    }
  }

  p {
    line-height: 1.2;
    font-size: 20px;
    width: 70%;
    @media #{$site-mobile-breakpoint} {
      width: 90%;
    }
  }
}

.text-block {
  max-width: 700px;
  padding: 88px 0 58px;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.87);

  @media #{$site-tablet-breakpoint} {
    max-width: 640px;
    padding: 87px 20px 61px;
  }
  @media #{$site-mobile-breakpoint} {
    padding: 86px 30px 64px;
  }

  h2 {
    margin-bottom: 41px;
    font-size: 24px;
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    line-height: 1.13;
    text-align: center;

    @media #{$site-mobile-breakpoint} {
      margin-bottom: 42px;
      font-size: 24px;
    }
  }

  p {
    margin-bottom: 24px;
    line-height: 1.5;
  }
}

.no-m-b {
  margin-bottom: 0 !important;
}

.ie11 {
  .inner-header__content {
    height: 100px;
  }

  .imaged-block .img img,
  .contact-us__wrap .img img {
    width: 100%;
  }
  .farms-images-image img{
    max-height: 200px;
  }
  @media #{$header-mobile-breakpoint} {
    .site-header__logo.inner img.gs-logo {
      width: 138px;
    }
  }
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;

  &:hover, &:focus {
    text-decoration: none;
  }
}

.card {
  &.light {
    background-color: $border-back-color;
  }

  &.green {
    background-color: $primary-green;
  }

  &.dark {

  }
}

ul.disc {
  list-style: disc;
  padding-left: 40px;
}
