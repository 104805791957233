@import "../../styles/variables";

.why-greensky {
  .inner-header {
    @include retina-back-external(
      "https://d1oeu1h51wn2cw.cloudfront.net/desktop/WhySolarity-Hero-Desktop",
      "jpg",
      no-repeat,
      50% 50%,
      #07417b,
      cover
    );

    h1 {
      font-size: 32px;
      line-height: 1.28;

      @media #{$site-mobile-breakpoint} {
        font-size: 24px;
        line-height: 1.15;
        margin-bottom: 0;
      }
    }

    &__content {
      padding: 85px 20px 114px;
      @media #{$site-tablet-breakpoint} {
        padding: 171px 20px 114px;

        min-height: 530px;
        line-height: 1.5;
        h1 {
          max-width: 550px;
          align-self: center;
          margin: 0 auto;

          br {
            display: none;
          }
        }
      }
      @media #{$site-mobile-breakpoint} {
        min-height: 0;
        padding: 107px 20px 154px;

        h1 {
          font-size: 32px;
          text-align: center;
          line-height: 1.28;
        }
      }
    }
  }

  &__table {
    h2 {
      margin-bottom: 11px;
      font-size: 24px;
      font-family: "Poppins", sans-serif;
      font-weight: 900;
      line-height: 1.13;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);

      @media #{$site-mobile-breakpoint} {
        font-size: 22px;
      }
    }

    .paragraph {
      text-align: center;
      margin-bottom: 41px;
      @media #{$site-mobile-breakpoint} {
        line-height: 1.15;
        padding: 0 10px;
      }
    }

    &-grid-1 {
      flex-basis: 40%;
      min-width: 40%;
      line-height: 1.2;
      font-size: 20px;
      margin-left: 4%;
      align-self: center;
      font-weight: 700;
      @media #{$site-tablet-breakpoint} {
        flex-basis: 31.3%;
        min-width: 31.3%;
        font-size: 16px;
        line-height: 1.5;
      }
      @media #{$site-mobile-breakpoint} {
        flex-basis: 100%;
        min-width: 100%;
        border-bottom: 1px solid #b3b3b3;
        margin: 0;
      }

      > div {
        @media #{$site-tablet-breakpoint} {
          padding-right: 20px;
        }
        @media #{$site-mobile-breakpoint} {
          padding: 15px;
        }
      }
    }

    &-grid-2 {
      flex-basis: 20.89%;
      min-width: 20.89%;
      font-size: 14px;
      text-align: center;
      margin-right: 3.5%;
      @media #{$site-tablet-breakpoint} {
        margin-right: 0;
        flex-basis: 30.09%;
        min-width: 30.09%;
      }
      @media #{$site-mobile-breakpoint} {
        flex-basis: 50%;
        min-width: 50%;
        margin: 0;
        text-align: left;
      }

      > div {
        padding: 23px 30px 14px;
        @media #{$site-tablet-breakpoint} {
          padding: 21px 20px 17px;
        }
        @media #{$site-mobile-breakpoint} {
          padding: 15px;
        }
      }
    }

    &-grid-3 {
      flex-basis: 26.09%;
      min-width: 26.09%;
      margin-right: 2.24%;
      background-color: rgba(235, 238, 239, 0.5);
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      display: flex;
      align-items: center;
      @media #{$site-tablet-breakpoint} {
        margin-right: 1.74%;
        flex-basis: 31.79%;
        min-width: 31.79%;
      }
      @media #{$site-mobile-breakpoint} {
        flex-basis: 50%;
        min-width: 50%;
        margin: 0;
        text-align: left;
      }
      > div {
        padding: 23px 49px 14px;
        @media #{$site-tablet-breakpoint} {
          padding: 21px 40px 17px;
        }
        @media #{$site-mobile-breakpoint} {
          padding: 15px;
        }
      }
    }
  }

  &__promise {
    background: #ebeeef;
  }

  &__table-centered {
    max-width: 1173px;
    margin: 0 auto;
    padding: 87px 52px 86px;

    @media #{$site-tablet-breakpoint} {
      padding: 87px 40px 86px;
    }
    @media #{$site-mobile-breakpoint} {
      padding: 46px 13px 40px;
    }
  }

  &__table-bordered {
    border: 1px solid #b3b3b3;
    border-radius: 3px;
  }

  &__table-wrap {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    line-height: 1.5;
    border-top: 2px solid #d9d9d9;
    @media #{$site-mobile-breakpoint} {
      border-top: 1px solid #b3b3b3;
    }

    &.title {
      background-color: #758083;
      color: #fff;
      font-family: Poppins, sans-serif;
      font-weight: 900;
      border: none;

      & + .why-greensky__table-wrap {
        border-top: none;
      }

      .why-greensky__table-grid-3,
      .why-greensky__table-grid-2 {
        background-color: transparent;
        font-size: 20px;
        display: block;
        > div {
          padding: 42px 0 32px;
        }
        @media #{$site-tablet-breakpoint} {
          font-size: 18px;
        }
        @media #{$site-mobile-breakpoint} {
          flex-basis: 50%;
          min-width: 50%;
          margin: 0;
          text-align: left;
          > div {
            padding: 15px;
          }
        }
      }

      @media #{$site-mobile-breakpoint} {
        .why-greensky__table-grid-1 {
          display: none;
        }
      }
    }
  }
}
